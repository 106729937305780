<template>
  <q-dialog ref="dialog" @hide="onCancelClick">
    <q-card :loading="loading" class="q-dialog-plugin">
      <q-card-section class="row items-center text-h6">
        <template v-if="$props.data">
          Fordonsskada: {{ $props.data.name }}
        </template>
      </q-card-section>
      <q-separator />
      <q-card-section class="q-pa-none q-ma-none" v-if="vehicleDefects.length">
        <q-list separator>
          <q-item
            v-for="vehicleDefect in vehicleDefects"
            :key="vehicleDefect.defect_number"
          >
            <q-item-section>
              <q-item-label v-if="vehicleDefect.defect_heading">{{
                vehicleDefect.defect_heading
              }}</q-item-label>
              <q-item-label caption
                ><template v-if="vehicleDefect.defect_date_and_time"
                  >{{
                    format(
                      new Date(vehicleDefect.defect_date_and_time),
                      'yyyy-MM-dd HH:mm'
                    )
                  }}
                  |</template
                >
                {{ vehicleDefect.defect_number }}</q-item-label
              >
            </q-item-section>
          </q-item>
          <q-separator />
        </q-list>
      </q-card-section>
      <template v-if="!loading && !vehicleDefects.length">
        <div class="flex justify-center q-mt-md">
          Fordonsskada kunde ej hittas!
        </div>
      </template>

      <div v-if="loading" class="flex justify-center q-mt-md">
        <q-spinner color="primary" size="3em" />
      </div>
      <q-card-actions align="right">
        <q-btn flat label="Stäng" @click="onCancelClick" type="button" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { getFordDefects } from '@/api/fordDefect/getFordDefect'
import { VehicleDefectByVehicle } from '@/api/vehicle-defect/getVehicleDefectByVehicle'
import { format } from 'date-fns'
import { defineComponent, PropType, ref } from 'vue'

export default defineComponent({
  name: 'GraphFordDefectModal',

  emits: ['ok', 'hide'],

  props: {
    data: {
      type: Object as PropType<{ name: string; date: string }>,
      required: true,
    },
  },

  setup(props, { emit }) {
    const vehicleDefects = ref<VehicleDefectByVehicle[]>([])
    const loading = ref(false)

    const fetchData = async () => {
      try {
        loading.value = true
        const res = await getFordDefects(props.data.name, props.data.date)

        if (res.status === 200) {
          vehicleDefects.value = res.data
        }
        loading.value = false
      } catch (e) {
        loading.value = false
        console.log(e)
      }
    }

    const onCancelClick = () => {
      emit('hide')
    }

    fetchData()

    return {
      onCancelClick,
      vehicleDefects,
      format,
      loading,
    }
  },
})
</script>
