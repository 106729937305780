import { instance } from '@/services/traind-mongo'
export interface AvikGraphTimetableItem {
  core: string
  departureDate: string
  direction: string
  eventMessageDatetime: string
  eventType: string
  operator: string
  owner: string
  placeEnd: string
  placeStart: string
  trainid: string
  typeOfTrain: string
  places: {
    actualArrivalTime: null
    actualDepartureTime: null
    distance: number
    location: string
    plannedArrivalTime: string | null
    plannedDepartureTime: string | null
    track: string | null
  }[]
}

export function getAvikGraphTimetable(params: {
  departureDate: string
  core: string
}): Promise<AvikGraphTimetableItem[]> {
  return instance
    .get<AvikGraphTimetableItem[]>(`/1/avik-graph-timetable`, {
      params,
    })
    .then((res) => {
      return res.data.map((x) => ({
        ...x,
        places: x.places.map((p) => ({
          ...p,
          location: p.location.toLowerCase(),
        })),
      }))
    })
}
