
import { getFordDefects } from '@/api/fordDefect/getFordDefect'
import { VehicleDefectByVehicle } from '@/api/vehicle-defect/getVehicleDefectByVehicle'
import { format } from 'date-fns'
import { defineComponent, PropType, ref } from 'vue'

export default defineComponent({
  name: 'GraphFordDefectModal',

  emits: ['ok', 'hide'],

  props: {
    data: {
      type: Object as PropType<{ name: string; date: string }>,
      required: true,
    },
  },

  setup(props, { emit }) {
    const vehicleDefects = ref<VehicleDefectByVehicle[]>([])
    const loading = ref(false)

    const fetchData = async () => {
      try {
        loading.value = true
        const res = await getFordDefects(props.data.name, props.data.date)

        if (res.status === 200) {
          vehicleDefects.value = res.data
        }
        loading.value = false
      } catch (e) {
        loading.value = false
        console.log(e)
      }
    }

    const onCancelClick = () => {
      emit('hide')
    }

    fetchData()

    return {
      onCancelClick,
      vehicleDefects,
      format,
      loading,
    }
  },
})
