import { Ref, computed, onBeforeUnmount, ref, watch } from 'vue'
import { addMinutes, format, subMinutes, roundToNearestMinutes } from 'date-fns'
import { getMainGraph } from '@/api/graph/getMainGraph'
import { searchDelay } from '@/api/search/searchDelay'
import { SearchDelay } from '@/types/search-delay'
import { getAvikGraphTimetable } from '@/api/graph/getAvikGraphTimetable'
import { getTrackCancellation } from '@/api/graph/getTrackCancellation'
import { getTrackCount } from '@/api/graph/getTrackCount'
import {
  getMpkEventMessage,
  MpkEventMessageData,
} from '@/api/graph/getMpkEventMessage'
import { useProfile } from './useProfile'

type StopTypeLong = 'DEPARTURE' | 'ARRIVAL' | 'RUN_THROUGH'

interface InfraData {
  place: string
  distance: number
  placeIndex: number
  delay: SearchDelay[]
  track: string | null
  track_count: number
}

type Infra = InfraData & {
  y: number
}

export interface GraphData {
  core_parsed: string
  actual: number
  actual_unix: string
  planned: number
  planned_unix: string
  departure_date: string
  stop_short: string
  stop_type_long: StopTypeLong
}

interface Graph {
  core_parsed: string
  departure_date: string
  selected: boolean
  path: {
    planned_time_mm: string
    actual_time_mm: string
    x1: number
    y1: number
    x2: number
    y2: number
  }[]
}

type TimelineZoomMode = 'minutes' | '5 minutes'
let ctx: CanvasRenderingContext2D | null = null
const hoverTrainId = ref<null | string>(null)

interface UseGraph {
  init: (canvas: HTMLCanvasElement) => void
  model: Ref<{
    trainNumber: string | null
    departureDate: string
  }>
  timelineZoomMode: Ref<TimelineZoomMode>
  setSelectedTrainNumber: (v: string | null, departureDate: string) => void
  setCanvasSize: () => void
  ctx: CanvasRenderingContext2D | null
  grafMode: Ref<GraphMode>
  showCirculations: Ref<boolean>
  loading: Ref<boolean>
  showOpal: Ref<boolean>
  events: {
    mpk_cb: (v: string) => void
    delay_cb: (advertised: string, departureDate: string) => void
    opal_cb: (mpkEventMessage: MpkEventMessageData[]) => void
  }
}

const dpi = window.devicePixelRatio
const LINE_COLOR = '#ccc'
const SIDEBAR_LEFT_WIDTH = 110
const SIDEBAR_RIGHT_WIDTH = 300
const TIMELINE_HEIGHT = 20

const viewport = {
  width: 0,
  height: 0,
}

const events = {
  mpk_cb: (value: string) => {
    //
  },
  delay_cb: (advertised: string, departureDate: string) => {
    //
  },
  opal_cb: (mpkEventMessage: MpkEventMessageData[]) => {
    //
  },
}

const setCanvasSize = () => {
  setTimeout(() => {
    if (!ctx) return
    const parentElement = ctx.canvas.parentElement?.getBoundingClientRect()
    if (!parentElement) return

    const width = parentElement.width
    const height = parentElement.height

    viewport.width = width
    viewport.height = height

    ctx.canvas.width = width * dpi
    ctx.canvas.height = height * dpi
    ctx.canvas.style.width = `${width}px`
    ctx.canvas.style.height = `${height}px`

    ctx.scale(dpi, dpi)
  }, 300)
}

const textOnLine = (
  ctx: CanvasRenderingContext2D,
  text: string | number,
  x0: number,
  y0: number,
  x1: number,
  y1: number
) => {
  const angle = Math.atan2(y1 - y0, x1 - x0)
  ctx.translate(x0, y0)
  ctx.rotate(angle)
  ctx.fillText(text.toString(), 0, 0)
  ctx.setTransform(dpi, 0, 0, dpi, 0, 0)
}

interface DrawContext {
  ctx: CanvasRenderingContext2D
  selectedTrainNumber: string | null
  mouse: {
    x: number
    y: number
  }
  getYWithScroll: (v: number) => number
  getXWithScroll: (v: number) => number
}

const drawActualGraphData = (
  {
    ctx,
    getYWithScroll,
    getXWithScroll,
    mouse,
    selectedTrainNumber,
  }: DrawContext,
  _graph: Graph[],
  plannedGraph: Graph[]
) => {
  ctx.fillStyle = '#000'
  ctx.textAlign = 'center'
  ctx.textBaseline = 'bottom'
  const graph = ['all', 'actual'].includes(grafMode.value)
    ? _graph
    : plannedGraph
  // check if over path
  let isHoverTrainId: string | null = null
  ctx.strokeStyle = 'transparent'
  ctx.lineWidth = 16

  const allData = [..._graph, ...plannedGraph]

  for (let i = 0; i < allData.length; i++) {
    const item = allData[i]
    const p = new Path2D()

    ctx.beginPath()
    for (let i = 0; i < item.path.length; i++) {
      const path = item.path[i]
      p.moveTo(getXWithScroll(path.x1), getYWithScroll(path.y1))
      p.lineTo(getXWithScroll(path.x2), getYWithScroll(path.y2))
    }
    ctx.stroke(p)
    ctx.closePath()

    if (
      ctx.isPointInStroke(p, mouse.x, mouse.y) &&
      !(
        mouse.x > viewport.width - SIDEBAR_RIGHT_WIDTH ||
        mouse.x < SIDEBAR_LEFT_WIDTH
      )
    ) {
      isHoverTrainId = item.core_parsed
    }
  }

  if (grafMode.value === 'all') {
    // Diff background planned - actual
    // const currentSelected = graph.find(
    //   (x) => x.core_parsed === selectedTrainNumber
    // )
    const currentPlannedSelected = plannedGraph.find(
      (x) => x.core_parsed === selectedTrainNumber
    )

    for (const plannedSelected of plannedGraph) {
      const currentSelected = graph.find(
        (x) => x.core_parsed === plannedSelected.core_parsed
      )

      if (
        currentSelected &&
        (hoverTrainId.value === plannedSelected.core_parsed ||
          currentPlannedSelected?.core_parsed === plannedSelected.core_parsed)
      ) {
        const buildPath = [
          ...plannedSelected.path.map((x) => ({
            x: x.x1,
            y: x.y1,
          })),
          {
            x: plannedSelected.path[plannedSelected.path.length - 1].x2,
            y: plannedSelected.path[plannedSelected.path.length - 1].y2,
          },
          {
            x: currentSelected.path[currentSelected.path.length - 1].x2,
            y: currentSelected.path[currentSelected.path.length - 1].y2,
          },
          ...currentSelected.path
            .slice()
            .reverse()
            .map((x) => ({
              x: x.x1,
              y: x.y1,
            })),
        ]

        const p = new Path2D()

        for (let i = 0; i < buildPath.length; i++) {
          const path = buildPath[i]
          p.lineTo(getXWithScroll(path.x), getYWithScroll(path.y))
        }

        p.closePath()

        if (selectedTrainNumber === plannedSelected.core_parsed) {
          ctx.fillStyle = 'rgba(0, 0, 255, .08)'
        } else {
          ctx.fillStyle = 'rgba(0, 255, 0, .08)'
        }

        ctx.fill(p)
      }
    }
  }

  for (let i = 0; i < graph.length; i++) {
    const item = graph[i]

    ctx.strokeStyle = item.selected
      ? '#0000ff'
      : hoverTrainId.value === item.core_parsed
      ? '#3BD166'
      : grafMode.value !== 'planned'
      ? '#000'
      : 'rgba(0, 0, 0, .2)'
    ctx.beginPath()

    for (let i = 0; i < item.path.length; i++) {
      const path = item.path[i]
      ctx.lineWidth = path.y1 === path.y2 ? 3 : 1
      if (path.y1 !== path.y2) {
        if (grafMode.value !== 'planned') {
          ctx.setLineDash([3, 2])
        }

        ctx.fillStyle = item.selected ? '#0000ff' : '#000'
        textOnLine(
          ctx,
          item.core_parsed,
          getXWithScroll(path.x1 + (path.x2 - path.x1) / 2),
          getYWithScroll(path.y1 + (path.y2 - path.y1) / 2),
          getXWithScroll(path.x2 + (path.x2 - path.x1) / 2),
          getYWithScroll(path.y2 + (path.y2 - path.y1) / 2)
        )
      } else {
        ctx.setLineDash([])
      }

      if (path.y1 === path.y2 && path.x2 > path.x1) {
        ctx.textAlign = 'right'
        ctx.fillText(
          ['all', 'actual'].includes(grafMode.value)
            ? path.actual_time_mm
            : path.planned_time_mm,
          getXWithScroll(path.x1),
          getYWithScroll(path.y1 + 14)
        )
      } else {
        ctx.textAlign = 'left'
        ctx.fillText(
          ['all', 'actual'].includes(grafMode.value)
            ? path.actual_time_mm
            : path.planned_time_mm,
          getXWithScroll(path.x1 + 4),
          getYWithScroll(path.y1)
        )
      }
      ctx.beginPath()

      ctx.moveTo(getXWithScroll(path.x1), getYWithScroll(path.y1))
      ctx.lineTo(getXWithScroll(path.x2), getYWithScroll(path.y2))

      ctx.stroke()
    }

    ctx.closePath()

    hoverTrainId.value = isHoverTrainId
  }
}

const drawMpkMessageRoutes = (
  { ctx, getYWithScroll, getXWithScroll }: DrawContext,
  mpkRouteData: MpkMessageRoute[]
) => {
  if (!showOpal.value) return
  for (const route of mpkRouteData) {
    const minX = getXWithScroll(route.boundryBox.minX)
    const maxX = getXWithScroll(route.boundryBox.maxX)

    const minY = getYWithScroll(route.boundryBox.minY)
    const maxY = getYWithScroll(route.boundryBox.maxY)

    const width = maxX - minX
    const height = maxY - minY

    if (minY === maxY) {
      ctx.fillStyle = 'red'
      ctx.fillRect(minX, minY - 2, width, 4)
    } else {
      ctx.fillStyle = 'rgba(255, 0, 0, .1)'
      ctx.fillRect(minX, minY, width, height)
    }
  }
}

const drawPlannedGraphData = (
  { ctx, getYWithScroll, getXWithScroll, selectedTrainNumber }: DrawContext,
  graph: Graph[]
) => {
  ctx.fillStyle = '#000'
  for (let i = 0; i < graph.length; i++) {
    const item = graph[i]
    ctx.strokeStyle =
      item.core_parsed === selectedTrainNumber
        ? 'rgba(0, 0, 255, .2)'
        : 'rgba(0, 0, 0, .2)'

    for (let i = 0; i < item.path.length; i++) {
      const path = item.path[i]

      ctx.lineWidth = path.y1 === path.y2 ? 3 : 1

      ctx.beginPath()
      ctx.moveTo(getXWithScroll(path.x1), getYWithScroll(path.y1))
      ctx.lineTo(getXWithScroll(path.x2), getYWithScroll(path.y2))
      ctx.stroke()
    }
  }

  ctx.closePath()
}

const capitalize = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1)
}

const model = ref<{
  trainNumber: null | string
  departureDate: string
}>({
  trainNumber: null,
  departureDate: format(new Date(), 'yyyy-MM-dd'),
})
type GraphMode = 'all' | 'planned' | 'actual'

interface MpkMessageRoute {
  data: MpkEventMessageData
  placeStart: string
  boundryBox: {
    minX: number
    maxX: number
    minY: number
    maxY: number
  }
}

interface GroupedMpkMessageRoute {
  [key: string]: MpkMessageRoute[]
}

const infra = ref<Infra[]>([])
const actual_graph = ref<Graph[]>([])
const planned_graph = ref<Graph[]>([])
const grafMode = ref<GraphMode>('all')
const showCirculations = ref(true)
const loading = ref(false)
const mpkMessageRoutes = ref<MpkMessageRoute[]>([])
const groupedMpkMessageRoutes = ref<GroupedMpkMessageRoute>({})
const showOpal = ref(false)

export const useGraph = (): UseGraph => {
  const timeline = ref({ start: new Date(), end: new Date() })
  const scrollLeft = ref<number>(0)
  const scrollTop = ref<number>(0)

  const mouse = ref({
    x: 0,
    y: 0,
  })

  timeline.value = {
    start: new Date(),
    end: new Date(),
  }

  const timelineZoomMode = ref<TimelineZoomMode>('minutes')
  const timelineZoomOperator = computed(() => {
    if (timelineZoomMode.value === 'minutes') return 1
    if (timelineZoomMode.value === '5 minutes') return 5
    return 60
  })

  const timelineZoomOperatorToPixelRepreseter = computed(() => {
    if (timelineZoomMode.value === 'minutes') return 20
    if (timelineZoomMode.value === '5 minutes') return 60
    return 60
  })

  const timelineZoomCaptionOperator = computed(() => {
    if (timelineZoomMode.value === 'minutes') return 5
    if (timelineZoomMode.value === '5 minutes') return 6
    return 60
  })

  const parseInfraDataYposition = (data: InfraData[]) => {
    let currentY = TIMELINE_HEIGHT
    const infraTotalDistance = ~~data.reduce<number>(
      (acc, x) => (acc += x.distance),
      0
    )
    return data.map((x) => {
      currentY = ~~(
        currentY +
        60 * data.length * (x.distance / infraTotalDistance) +
        16
      )
      return {
        ...x,
        y: currentY,
      }
    })
  }

  watch(
    () => timelineZoomMode.value,
    () =>
      setSelectedTrainNumber(model.value.trainNumber, model.value.departureDate)
  )

  watch(
    () => hoverTrainId.value,
    (value) => {
      if (value) {
        document.body.style.cursor = 'pointer'
      } else {
        document.body.style.cursor = ''
      }
    }
  )

  const calcXposition = (date: Date) => {
    return ~~(
      ((+date - +timeline.value.start) / 60000) *
      (timelineZoomOperatorToPixelRepreseter.value / timelineZoomOperator.value)
    )
  }

  const parseGraphData = (type: 'planned' | 'actual', data: GraphData[]) => {
    return [...new Set(data.map((x) => x.core_parsed))].reduce<Graph[]>(
      (acc, core_parsed) => {
        const items = data.filter((x) => x.core_parsed === core_parsed)
        const payload: Graph = {
          core_parsed,
          departure_date: '',
          selected: model.value.trainNumber === core_parsed,
          path: [],
        }
        for (let i = 0; i < items.length; i++) {
          const item = items[i]
          const nextItem = items[i + 1]

          if (!nextItem) break
          const x1 =
            type === 'actual'
              ? calcXposition(new Date(item.actual_unix))
              : calcXposition(new Date(item.planned_unix))
          const x2 =
            type === 'actual'
              ? calcXposition(new Date(nextItem.actual_unix))
              : calcXposition(new Date(nextItem.planned_unix))
          payload.departure_date = item.departure_date
          payload.path.push({
            x1: SIDEBAR_LEFT_WIDTH + (x1 || 0),
            y1: infra.value.find((x) => x.place === item.stop_short)?.y || 0,
            x2: SIDEBAR_LEFT_WIDTH + (x2 || 0),
            y2:
              infra.value.find((x) => x.place === nextItem.stop_short)?.y || 0,
            planned_time_mm: format(new Date(item.planned_unix), 'mm'),
            actual_time_mm: format(new Date(item.actual_unix), 'mm'),
          })
        }

        acc.push(payload)

        return acc
      },
      []
    )
  }

  const parseMpkEventMessageData = (data: MpkEventMessageData[]) => {
    const routes: MpkMessageRoute[] = []

    const findRoutes = (route: string[]) => {
      if (route.length === 1) return [[route[0].toLowerCase()]]
      return route
        .reduce<string[][]>((acc, place) => {
          const foundInfraPlaceIndex = infra.value.findIndex(
            (x) => x.place === place.toLowerCase()
          )
          const prevInfraPlace = infra.value[foundInfraPlaceIndex - 1]

          if (foundInfraPlaceIndex < 0) return acc

          if (acc.length === 0) {
            acc.push([place.toLowerCase()])
            return acc
          }

          const accLastIndex = acc.length - 1

          if (
            prevInfraPlace &&
            prevInfraPlace.place ===
              acc[accLastIndex][acc[accLastIndex].length - 1]
          ) {
            acc[acc.length - 1].push(place.toLowerCase())
          }

          return acc
        }, [])
        .filter((x) => x.length > 1)
    }

    for (const item of data) {
      for (const route of item.PåverkadeSträckor) {
        const route1 = findRoutes(route)
        const route2 = findRoutes(route.slice().reverse())

        const useRouteMap = route1.length >= route2.length ? route1 : route2

        for (const routeMap of useRouteMap) {
          const placeStart = infra.value.find(
            (x) => x.place === routeMap[0].toLowerCase()
          )
          const placeEnd = infra.value.find(
            (x) => x.place === routeMap[routeMap.length - 1].toLowerCase()
          )

          if (!placeStart || !placeEnd) continue

          const payload = {
            data: item,
            placeStart: placeStart.place,
            boundryBox: {
              minX: Math.max(
                SIDEBAR_LEFT_WIDTH +
                  calcXposition(new Date(item.StartDateTime)),
                SIDEBAR_LEFT_WIDTH
              ),
              maxX: Math.min(
                SIDEBAR_LEFT_WIDTH + calcXposition(new Date(item.StopDateTime)),
                SIDEBAR_LEFT_WIDTH + calcXposition(timeline.value.end)
              ),
              minY: placeStart.y || 0,
              maxY: placeEnd.y || 0,
            },
          }

          routes.push(payload)
        }
      }
    }

    return routes
  }

  const timelineWidth = computed(
    () =>
      ((+timeline.value.end - +timeline.value.start) /
        (60000 * timelineZoomOperator.value)) *
      timelineZoomOperator.value
  )

  const timelineChunks = computed(() => {
    return new Array(
      Math.ceil(timelineWidth.value / timelineZoomOperator.value) || 0
    )
      .fill(null)
      .map((x, i) => {
        return {
          caption:
            i % timelineZoomCaptionOperator.value === 0
              ? format(
                  addMinutes(
                    timeline.value.start,
                    i * timelineZoomOperator.value
                  ),
                  'HH:mm'
                )
              : '',
        }
      })
  })

  const canvasHeight = computed(() => {
    return Math.max(...infra.value.map((x) => x.y)) + 24
  })

  const canvasWidth = computed(() => {
    return (
      SIDEBAR_RIGHT_WIDTH +
      SIDEBAR_LEFT_WIDTH +
      timelineWidth.value *
        (timelineZoomOperatorToPixelRepreseter.value /
          timelineZoomOperator.value)
    )
  })

  const onResize = () => setCanvasSize()

  const scrollContainer = document.createElement('div')
  scrollContainer.style.pointerEvents = 'none'

  const getYWithScroll = (y: number) => y - scrollTop.value
  const getXWithScroll = (x: number) => x - scrollLeft.value

  const getColor = (v: number) => {
    if (v > 5) return 'red'
    if (v < 0) return 'blue'
    if (v === 0) return 'green'
    return 'orange'
  }

  // RITA
  const draw = () => {
    if (!ctx) return
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)

    ctx.font = 'normal 10px Arial'
    ctx.fillStyle = LINE_COLOR
    for (let i = 0; i < timelineChunks.value.length; i++) {
      if (!timelineChunks.value[i].caption) continue
      const x = getXWithScroll(
        SIDEBAR_LEFT_WIDTH + i * timelineZoomOperatorToPixelRepreseter.value
      )
      ctx.fillStyle = LINE_COLOR
      ctx.fillRect(x, TIMELINE_HEIGHT, 1, viewport.height)
    }
    for (let i = 0; i < infra.value.length; i++) {
      const infraItem = infra.value[i]
      ctx.fillRect(
        SIDEBAR_LEFT_WIDTH,
        getYWithScroll(infraItem.y),
        viewport.width - SIDEBAR_LEFT_WIDTH,
        1
      )
      ctx.fillStyle = LINE_COLOR
      for (let i = 0; i < timelineChunks.value.length; i++) {
        const lineHeight = 5
        const x = getXWithScroll(
          SIDEBAR_LEFT_WIDTH + i * timelineZoomOperatorToPixelRepreseter.value
        )

        ctx.fillRect(x, getYWithScroll(infraItem.y - 2), 1, lineHeight)
      }
    }

    const context = {
      ctx,
      getXWithScroll,
      getYWithScroll,
      mouse: mouse.value,
      selectedTrainNumber: model.value.trainNumber,
    }

    drawMpkMessageRoutes(context, mpkMessageRoutes.value)

    // graf data axis
    if (grafMode.value === 'all' || grafMode.value === 'planned') {
      drawPlannedGraphData(context, planned_graph.value)
    }
    drawActualGraphData(context, actual_graph.value, planned_graph.value)

    // render box
    ctx.fillStyle = '#fff'
    const x = SIDEBAR_LEFT_WIDTH + calcXposition(timeline.value.end)
    ctx.fillRect(x, 0, context.ctx.canvas.width - x, context.ctx.canvas.height)
    ctx.fillStyle = '#ccc'
    ctx.fillRect(x, 0, 1, context.ctx.canvas.height)

    // sidebar left
    ctx.textBaseline = 'top'
    ctx.fillStyle = LINE_COLOR
    ctx.fillRect(SIDEBAR_LEFT_WIDTH, 0, 1, viewport.height)

    // infra places axis
    ctx.textAlign = 'right'
    ctx.font = 'bold 11px Arial'
    ctx.fillStyle = '#fff'
    ctx.fillRect(
      0,
      TIMELINE_HEIGHT,
      SIDEBAR_LEFT_WIDTH,
      viewport.height - TIMELINE_HEIGHT
    )
    for (let i = 0; i < infra.value.length; i++) {
      ctx.fillStyle = '#000'
      const infraItem = infra.value[i]
      const infraItemNext = infra.value[i + 1]
      ctx.fillText(
        capitalize(infraItem.place),
        SIDEBAR_LEFT_WIDTH - 10,
        getYWithScroll(infraItem.y - 5)
      )
      if (infraItemNext && infraItem.track_count) {
        for (let i = 0; i < infraItem.track_count; i++) {
          const y1 = getYWithScroll(infraItem.y) + 7
          const y2 = getYWithScroll(infraItemNext.y) - 7
          ctx.fillRect(SIDEBAR_LEFT_WIDTH - 12 - i * 8, y1, 2, y2 - y1)
        }
      }
    }

    // mpk message route
    ctx.font = 'normal 11px Arial'
    ctx.setLineDash([0])
    ctx.strokeStyle = '#222'
    ctx.lineWidth = 1
    ctx.fillStyle = '#000'
    ctx.textAlign = 'left'
    for (const placeStart in groupedMpkMessageRoutes.value) {
      const routes = groupedMpkMessageRoutes.value[placeStart]
      const route = routes[0]

      const text = routes.length > 1 ? `Opal: (${routes.length}st)` : `Opal`

      const y = getYWithScroll(route.boundryBox.minY) - 10

      const textWidth = ~~ctx.measureText(text).width

      const x = SIDEBAR_LEFT_WIDTH - textWidth - 50 - 5

      ctx.strokeRect(x + 0.5, y + 0.5, textWidth + 12, 18)
      ctx.fillText(text, x + 6, y + 4)
    }

    // sidebar right
    // infra places axis
    ctx.textAlign = 'left'

    ctx.fillStyle = '#fff'
    ctx.fillRect(
      viewport.width - SIDEBAR_RIGHT_WIDTH,
      TIMELINE_HEIGHT,
      SIDEBAR_RIGHT_WIDTH,
      viewport.height - TIMELINE_HEIGHT
    )

    ctx.textBaseline = 'top'
    ctx.fillStyle = LINE_COLOR
    ctx.fillRect(
      viewport.width - SIDEBAR_RIGHT_WIDTH,
      TIMELINE_HEIGHT,
      1,
      viewport.height
    )

    for (let i = 0; i < infra.value.length; i++) {
      ctx.fillStyle = '#000'
      const infraItem = infra.value[i]
      ctx.font = 'bold 11px Arial'
      ctx.fillText(
        capitalize(infraItem.place),
        viewport.width - SIDEBAR_RIGHT_WIDTH + 10,
        getYWithScroll(infraItem.y - 5)
      )
      ctx.font = 'normal 11px Arial'
      if (infraItem.track) {
        ctx.fillText(
          `sp ${infraItem.track}`,
          viewport.width - SIDEBAR_RIGHT_WIDTH + 10,
          getYWithScroll(infraItem.y - 5 + 14)
        )
      }

      for (let j = 0; j < infraItem.delay.length; j++) {
        const delay = infraItem.delay[j]
        ctx.font = 'bold 11px Arial'
        ctx.fillStyle = getColor(delay.delay_minutes)

        ctx.fillText(
          delay.delay_minutes.toString(),
          viewport.width - SIDEBAR_RIGHT_WIDTH + 90,
          j * 14 + getYWithScroll(infraItem.y - 5)
        )

        ctx.font = 'normal 11px Arial'
        ctx.fillStyle = 'black'

        ctx.fillText(
          delay.planned,
          viewport.width - SIDEBAR_RIGHT_WIDTH + 50,
          j * 14 + getYWithScroll(infraItem.y - 5)
        )

        const lengthText = delay.level_3_description?.length || 0

        ctx.fillText(
          `${delay.delay_reason || ''} ${
            delay.level_3_description
              ? ` | ${
                  lengthText > 10
                    ? `${delay.level_3_description?.substring(0, 10)}...`
                    : delay.level_3_description
                }`
              : ''
          }`,
          viewport.width - SIDEBAR_RIGHT_WIDTH + 110,
          j * 14 + getYWithScroll(infraItem.y - 5)
        )

        if (delay.delay_reason_description) {
          const textWidth = ~~ctx.measureText(delay.delay_reason_description)
            .width
          ctx.setLineDash([0])
          ctx.strokeStyle = '#222'
          ctx.lineWidth = 1
          ctx.strokeRect(
            viewport.width - SIDEBAR_RIGHT_WIDTH + 230 + 0.5 - 4,
            j * 20 + getYWithScroll(infraItem.y - 5) + 0.5 - 5,
            textWidth + 8,
            18
          )
          ctx.fillText(
            delay.delay_reason_description,
            viewport.width - SIDEBAR_RIGHT_WIDTH + 230,
            j * 20 + getYWithScroll(infraItem.y - 5)
          )
        }
      }
    }

    // timeline
    ctx.textBaseline = 'top'
    ctx.fillStyle = '#fff'
    ctx.fillRect(0, 0, viewport.width, TIMELINE_HEIGHT)
    ctx.textAlign = 'left'
    ctx.fillStyle = LINE_COLOR
    ctx.fillRect(0, TIMELINE_HEIGHT, viewport.width, 1)
    for (let i = 0; i < timelineChunks.value.length; i++) {
      const timeChunk = timelineChunks.value[i]
      const lineHeight = timeChunk.caption
        ? TIMELINE_HEIGHT / 2
        : TIMELINE_HEIGHT / 4
      const x = getXWithScroll(
        SIDEBAR_LEFT_WIDTH + i * timelineZoomOperatorToPixelRepreseter.value
      )
      ctx.fillStyle = LINE_COLOR
      ctx.fillRect(x, TIMELINE_HEIGHT - lineHeight, 1, lineHeight)

      ctx.fillStyle = '#000'
      ctx.fillText(timeChunk.caption, x + 4, TIMELINE_HEIGHT / 2)
    }

    // ctx.fillStyle = '#fff'
    // ctx.fillRect(
    //   SIDEBAR_LEFT_WIDTH +
    //     getXWithScroll(
    //       timelineChunks.value.length *
    //         timelineZoomOperatorToPixelRepreseter.value
    //     ),
    //   0,
    //   viewport.width,
    //   viewport.height
    // )
    // ctx.fillStyle = LINE_COLOR
    // ctx.fillRect(
    //   SIDEBAR_LEFT_WIDTH +
    //     getXWithScroll(
    //       timelineChunks.value.length *
    //         timelineZoomOperatorToPixelRepreseter.value
    //     ),
    //   0,
    //   1,
    //   viewport.height
    // )

    // hover info
    const hoverItem = actual_graph.value.find(
      (x) => x.core_parsed === hoverTrainId.value
    )
    if (hoverItem) {
      ctx.setLineDash([])
      ctx.lineWidth = 1

      ctx.font = 'normal 14px Arial'

      const x = mouse.value.x / dpi - 75
      const y = mouse.value.y / dpi
      ctx.save()
      ctx.fillStyle = 'rgb(255, 255, 255, .8)'
      ctx.fillRect(x, y, 50, 16)
      ctx.strokeStyle = 'solid'
      ctx.strokeStyle = '#000'
      ctx.strokeRect(~~x + 0.5, ~~y + 0.5, 50, 16)
      ctx.fillStyle = '#000'
      ctx.textBaseline = 'bottom'
      ctx.textAlign = 'center'
      ctx.fillText(hoverItem.core_parsed, x + 25, y + 16)
    }
  }

  const render = () => {
    draw()
    window.requestAnimationFrame(render)
  }

  const setSelectedTrainNumber = async (
    trainId: string | null | number,
    departureDate: string
  ) => {
    const parsedTrainId =
      trainId && typeof trainId === 'string'
        ? trainId
        : !trainId
        ? null
        : trainId.toString()
    model.value.trainNumber = parsedTrainId
    actual_graph.value = []
    planned_graph.value = []
    mpkMessageRoutes.value = []
    groupedMpkMessageRoutes.value = {}
    infra.value = []
    let grafdata: GraphData[] = []

    if (parsedTrainId) {
      loading.value = true
      const { currentProject } = useProfile()
      window.history.pushState(
        null,
        '',
        `/${currentProject.value?.name}/graph/${parsedTrainId}/${departureDate}`
      )

      const params = {
        trainId: parsedTrainId,
        departureDate,
      }

      const trackCountData = await getTrackCount()

      const graphTimetableData = await getAvikGraphTimetable({
        departureDate,
        core: parsedTrainId,
      })

      const main_graph_data = await getMainGraph(params)
      const { data } = await searchDelay({
        technical: parsedTrainId,
        departureDate,
      })

      loading.value = false

      grafdata = main_graph_data.map((x) => ({
        ...x,
        stop_short: x.stop_short.toLowerCase(),
      }))

      const delayData = data.reduce<(SearchDelay & { placeIndex: number })[]>(
        (acc, item) => {
          const placeIndex = acc.filter(
            (x) =>
              x.stop_short === item.stop_short &&
              x.stop_type_long === item.stop_type_long
          ).length

          acc.push({
            ...item,
            placeIndex,
          })

          return acc
        },
        []
      )

      infra.value = parseInfraDataYposition(
        (graphTimetableData.find((_x, i) => i === 0)?.places || [])
          .reduce<
            {
              distance: number
              stop_short: string
              placeIndex: number
              track_count: number
              track: string | null
              delay: SearchDelay[]
            }[]
          >((acc, x, i, arr) => {
            const placeIndex = acc.filter(
              (a) => a.stop_short === x.location
            ).length
            const stop_short = x.location.toLowerCase()
            if (
              acc.length > 0 &&
              acc[acc.length - 1].stop_short === stop_short
            ) {
              return acc
            }

            const foundTrackData = trackCountData.find(
              (x) => x.from.toLowerCase() === stop_short
            )

            let track_count = 0

            if (foundTrackData) {
              const to =
                arr[i + 1]?.location.toLowerCase() ===
                foundTrackData.to.toLowerCase()

              if (to) {
                track_count = foundTrackData.track_count
              }
            }

            const data = {
              stop_short,
              track: x.track,
              track_count,
              distance: x.distance,
              placeIndex,
              delay: delayData
                .filter(
                  (x) =>
                    x.stop_short.toLowerCase() === stop_short &&
                    x.placeIndex === placeIndex
                )
                .map((x) => ({
                  ...x,
                  delay_reason_description: x.delay_reason_description
                    ? x.delay_reason_description.split('(')[0]
                    : null,
                  planned: format(new Date(x.planned), 'HH:mm'),
                  actual: format(new Date(x.actual), 'HH:mm'),
                })),
            }

            acc.push(data)

            return acc
          }, [])
          .map((x) => ({
            track: x.track,
            track_count: x.track_count,
            distance: x.distance,
            delay: x.delay,
            placeIndex: x.placeIndex,
            place: x.stop_short,
          }))
      )
    }

    const selectedTrips = grafdata.filter(
      (x) => x.core_parsed === model.value.trainNumber
    )
    timeline.value = {
      start: subMinutes(
        roundToNearestMinutes(
          new Date(
            Math.min(...selectedTrips.map((x) => +new Date(x.actual_unix)))
          ),
          {
            nearestTo: 15,
          }
        ),
        15
      ),
      end: addMinutes(
        roundToNearestMinutes(
          new Date(
            Math.max(...selectedTrips.map((x) => +new Date(x.actual_unix)))
          ),
          {
            nearestTo: 15,
          }
        ),
        15
      ),
    }

    const params = {
      fromTime: format(timeline.value.start, 'yyyy-MM-dd HH:mm:ss').replace(
        ' ',
        'T'
      ),
      toTime: format(timeline.value.end, 'yyyy-MM-dd HH:mm:ss').replace(
        ' ',
        'T'
      ),
      places: infra.value.map((x) => x.place),
    }

    const mpkEventMessageData = await getMpkEventMessage(params)
    const trackCancellationData = await getTrackCancellation(params)

    // TODO: track canceellation
    console.log(trackCancellationData)

    mpkMessageRoutes.value = parseMpkEventMessageData(mpkEventMessageData)

    groupedMpkMessageRoutes.value =
      mpkMessageRoutes.value.reduce<GroupedMpkMessageRoute>((acc, route) => {
        if (!acc[route.placeStart]) {
          acc[route.placeStart] = []
        }

        acc[route.placeStart].push(route)

        return acc
      }, {})

    actual_graph.value = parseGraphData('actual', grafdata)
    planned_graph.value = parseGraphData('planned', grafdata)
  }

  watch(
    () => canvasWidth.value,
    () => {
      if (scrollContainer) {
        scrollContainer.style.width = `${canvasWidth.value}px`
        scrollContainer.style.height = `${canvasHeight.value}px`
      }
    }
  )

  const init: UseGraph['init'] = (canvas) => {
    canvas.style.display = 'block'
    ctx = canvas.getContext('2d')
    if (!ctx) return

    setCanvasSize()
    const onResizeFn = () => ctx && onResize()
    window.addEventListener('resize', onResizeFn)

    const onMouseMove = (e: MouseEvent) => {
      const rect = canvas.getBoundingClientRect()
      const x = e.clientX - rect.left
      const y = e.clientY - rect.top

      if (x > viewport.width - SIDEBAR_RIGHT_WIDTH || y < TIMELINE_HEIGHT) {
        hoverTrainId.value = null
        return
      }

      mouse.value.x = x * dpi
      mouse.value.y = y * dpi
    }

    const onMouseDown = (e: MouseEvent) => {
      const rect = canvas.getBoundingClientRect()
      const x = e.clientX - rect.left
      const y = e.clientY - rect.top
      mouse.value.x = x * dpi
      mouse.value.y = y * dpi

      if (ctx) {
        const { height, width } = ctx?.canvas.getBoundingClientRect()
        if (
          mouse.value.x / dpi >= width - 20 ||
          mouse.value.y / dpi >= height - 20
        )
          return
      }

      if (ctx) {
        const x = mouse.value.x / dpi
        const y = mouse.value.y / dpi
        let isDelay = false
        for (let i = 0; i < infra.value.length; i++) {
          const infraItem = infra.value[i]

          for (let j = 0; j < infraItem.delay.length; j++) {
            const delay = infraItem.delay[j]
            if (!delay.delay_reason_description) continue
            const textWidth = ~~ctx.measureText(delay.delay_reason_description)
              .width
            const rect = {
              x: viewport.width - SIDEBAR_RIGHT_WIDTH + 230 + 0.5 - 4,
              y: j * 14 + getYWithScroll(infraItem.y - 5) + 0.5 - 5,
              width: textWidth + 8,
              height: 18,
            }

            const bb = {
              x1: rect.x,
              x2: rect.x + rect.width,
              y1: rect.y,
              y2: rect.y + rect.height,
            }

            if (x >= bb.x1 && x <= bb.x2 && y >= bb.y1 && y && y <= bb.y2) {
              if (delay.delay_reason_description[0] === 'H') {
                events.mpk_cb(delay.delay_reason_description)
              } else {
                events.delay_cb(
                  delay.delay_reason_description,
                  model.value.departureDate
                )
              }
              isDelay = true
              break
            }
          }
          const routes = groupedMpkMessageRoutes.value[infraItem.place]
          if (routes && !isDelay) {
            const text =
              routes.length > 1 ? `Opal: (${routes.length}st)` : `Opal`

            const textWidth = ~~ctx.measureText(text).width
            const xPos = SIDEBAR_LEFT_WIDTH - textWidth - 50 - 5

            const rect = {
              x: xPos,
              y: getYWithScroll(infraItem.y - 10),
              width: textWidth + 12,
              height: 18,
            }

            const bb = {
              x1: rect.x,
              x2: rect.x + rect.width,
              y1: rect.y,
              y2: rect.y + rect.height,
            }

            if (x >= bb.x1 && x <= bb.x2 && y >= bb.y1 && y <= bb.y2) {
              isDelay = true
              events.opal_cb(routes.map((x) => x.data))
              break
            }
          }
        }
        if (isDelay) return
      }

      if (x > viewport.width - SIDEBAR_RIGHT_WIDTH || x < SIDEBAR_LEFT_WIDTH) {
        return
      }

      if (hoverTrainId.value) {
        const item = actual_graph.value.find(
          (x) => x.core_parsed === hoverTrainId.value
        )
        if (item) {
          model.value.trainNumber = hoverTrainId.value
          model.value.departureDate = item.departure_date
        }
      }
    }

    canvas.addEventListener('mousemove', onMouseMove, false)
    canvas.addEventListener('mousedown', onMouseDown)

    onBeforeUnmount(() => {
      canvas.removeEventListener('mousemove', onMouseMove, false)
      canvas.removeEventListener('mousedown', onMouseDown)
    })

    if (canvas.parentElement) {
      canvas.parentElement.style.position = 'relative'
      canvas.parentElement.style.overflow = 'auto'
      canvas.parentElement.classList.add('ganttScroll')

      canvas.style.position = 'sticky'
      canvas.style.top = '0'
      canvas.style.left = '0'
      scrollContainer.style.position = 'absolute'
      scrollContainer.style.left = '0'
      scrollContainer.style.top = '0'

      scrollContainer.style.width = `${canvasWidth.value}px`
      scrollContainer.style.height = `${canvasHeight.value}px`
      // const prevScrollTop = ref(0)
      canvas.parentElement.appendChild(scrollContainer)
      canvas.parentElement.addEventListener('scroll', (e) => {
        const { scrollLeft: sl, scrollTop: st } = e.target as HTMLCanvasElement
        scrollLeft.value = sl
        scrollTop.value = st

        // if (prevScrollTop.value === st) return
        // prevScrollTop.value = st
        // const current = actual_graph.value.find((x) => x.selected)
        // if (!current || !canvas.parentElement) return

        // const width = canvas.parentElement.clientWidth
        // const height = canvas.parentElement.clientHeight
        // Calculate the slope (m) of the line: (y2 - y1) / (x2 - x1)

        // const startPoint = current.path.find(
        //   (x) =>
        //     x.x1 > sl + SIDEBAR_LEFT_WIDTH &&
        //     x.x1 < sl + width - SIDEBAR_LEFT_WIDTH - SIDEBAR_RIGHT_WIDTH &&
        //     x.y1 > st + TIMELINE_HEIGHT &&
        //     x.y1 < st + height - TIMELINE_HEIGHT
        // ) || { y1: 0, x1: 0, y2: 0, x2: 0 }
        // const endpoint = current.path
        //   .slice()
        //   .reverse()
        //   .find(
        //     (x) =>
        //       x.x1 > sl + SIDEBAR_LEFT_WIDTH &&
        //       x.x1 < sl + width - SIDEBAR_LEFT_WIDTH - SIDEBAR_RIGHT_WIDTH &&
        //       x.y1 > st + TIMELINE_HEIGHT &&
        //       x.y1 < st + height - TIMELINE_HEIGHT
        //   ) || { y2: 0, x2: 0, y1: 0, x1: 0 }

        // const slope =
        //   (endpoint.y2 - startPoint.y1) / (endpoint.x2 - startPoint.x1)

        // Calculate the corresponding scrollLeft based on scrollTop
        // const maxScrollLeft = canvas.parentElement.scrollWidth - width
        // const correspondingScrollLeft = st / slope || st

        // Ensure scrollLeft stays within bounds

        // canvas.parentElement.scrollLeft = Math.min(
        //   maxScrollLeft,
        //   Math.max(0, correspondingScrollLeft)
        // )
      })
    }

    onBeforeUnmount(() => {
      window.removeEventListener('resize', onResizeFn)
    })

    window.requestAnimationFrame(render)
  }

  return {
    init,
    timelineZoomMode,
    setSelectedTrainNumber,
    model,
    setCanvasSize,
    ctx,
    grafMode,
    events,
    showCirculations,
    loading,
    showOpal,
  }
}
