
import { computed, defineComponent, provide, ref, watch } from 'vue'
import GraphView from '@/components/graph/GraphView.vue'
import GraphCirculation from '@/components/graph/GraphCirculation.vue'
import { useGraph } from '@/composable/useGraph'
import { useApp } from '@/composable/useApp'
import DeviationDelayMPKModal from '@/components/deviation/deviation-delay/DeviationDelayMPKModal.vue'
import DeviationDelayMPKMultipleModal from '@/components/deviation/deviation-delay/DeviationDelayMPKMultipleModal.vue'
import { useModal } from '@/composable/useModal'
import { getMpkEvent } from '@/api/mpk/getMpkEvent'
import { searchDelay } from '@/api/search/searchDelay'
import { searchPrevTripFromTraind } from '@/api/search/prevTripFromTraind'
import DeviationDelayModal from '@/components/deviation/DeviationDelayModal.vue'
import { useGraphCirculation } from '@/composable/useGraphCirculation'
import { MpkEventMessageData } from '@/api/graph/getMpkEventMessage'

export default defineComponent({
  components: {
    GraphView,
    GraphCirculation,
    DeviationDelayMPKModal,
    DeviationDelayModal,
    DeviationDelayMPKMultipleModal,
  },

  setup() {
    const opalModal = useModal()
    const mpkModal = useModal()
    const delayModal = useModal()
    provide('opal-modal', opalModal)
    provide('delay-mpk-modal', mpkModal)
    provide('delay-modal-2', delayModal)
    const {
      model,
      events,
      showCirculations,
      timelineZoomMode,
      grafMode,
      showOpal,
      loading: loadingGraph,
    } = useGraph()
    const { loading: loadingCirculation } = useGraphCirculation()
    const { showHeader } = useApp()

    const tempTrainNumber = ref(model.value.trainNumber)

    watch(
      () => model.value.trainNumber,
      (value) => {
        tempTrainNumber.value = value
      }
    )

    const onSearchTrainNumber = () => {
      model.value.trainNumber = tempTrainNumber.value
    }

    const onDepartureDateChange = (v: string) => {
      if (v.length !== 10) return
      model.value.departureDate = v
    }

    const toggleShowHeader = () => {
      showHeader.value = !showHeader.value
    }

    events.mpk_cb = (mpk_event_id: string) => {
      const eventId = mpk_event_id.split(' ')[0].replace('H', '')
      mpkModal?.openModal({
        cb: async (setData) => {
          await getMpkEvent(eventId)
            .then(({ data }) => {
              setData({
                eventId,
                mpkData: data,
              })
            })
            .catch((error) => {
              setData(error.response.data.message)
            })

          return Promise.resolve()
        },
      })
    }

    events.opal_cb = (mpkMessageData: MpkEventMessageData[]) => {
      opalModal?.openModal({
        cb: async (setData) => {
          setData({
            mpkMessageData,
          })
        },
      })
    }

    events.delay_cb = (advertised: string, departureDate: string) => {
      delayModal?.openModal({
        cb: async (setData) => {
          const params = {
            technical: advertised,
            departureDate: departureDate,
          }
          await Promise.all([
            searchDelay(params),
            searchPrevTripFromTraind({
              tnr: parseInt(params.technical, 10),
              date: params.departureDate,
            }),
          ])
            .then(([{ data }, { data: prevTripFromTraind }]) => {
              setData({
                data,
                prevTripFromTraind,
              })
            })
            .catch((error) => {
              setData(error.response.data.message)
            })

          return Promise.resolve()
        },
      })
    }

    const loading = computed(() => {
      return loadingGraph.value || loadingCirculation.value
    })

    return {
      onSearchTrainNumber,
      tempTrainNumber,
      model,
      onDepartureDateChange,
      toggleShowHeader,
      showHeader,
      mpkModal,
      delayModal,
      showCirculations,
      timelineZoomMode,
      grafMode,
      loading,
      opalModal,
      showOpal,
    }
  },
})
