import { instance } from '@/services/avik-api-gateway'
import { GraphData } from '@/composable/useGraph'

export function getMainGraph(params: {
  departureDate: string
  trainId: string
}): Promise<GraphData[]> {
  return instance
    .get<(GraphData & { otn: string })[]>(`/graph/main-graph`, {
      params,
    })
    .then<GraphData[]>((res) => {
      return res.data.map((x) => ({
        ...x,
        otn: parseInt(x.otn, 10),
      }))
    })
}
