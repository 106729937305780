<template>
  <q-card>
    <div :class="$style.overlay" v-if="!circulations.length || loading">
      {{ loading ? 'Hämtar...' : 'Ingen omlopps data hittades...' }}
    </div>
    <q-card-section class="q-pa-none">
      <canvas ref="canvas" />
    </q-card-section>
  </q-card>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import { useGraphCirculation } from '@/composable/useGraphCirculation'

export default defineComponent({
  setup() {
    const canvas = ref<HTMLCanvasElement>()
    const { init, circulations, loading } = useGraphCirculation()

    onMounted(() => {
      if (!canvas.value) return

      init(canvas.value)
    })

    return {
      canvas,
      circulations,
      loading,
    }
  },
})
</script>

<style lang="scss" module>
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(white, 0.8);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  color: rgba(black, 0.8);
}
</style>
