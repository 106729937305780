import { render } from "./GraphFordDefectModal.vue?vue&type=template&id=f586cebe"
import script from "./GraphFordDefectModal.vue?vue&type=script&lang=ts"
export * from "./GraphFordDefectModal.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QCard,QCardSection,QSeparator,QList,QItem,QItemSection,QItemLabel,QSpinner,QCardActions,QBtn});
