<template>
  <q-card>
    <q-card-section class="q-pa-none">
      <!-- <q-toolbar style="min-height: 24px">
        <q-space />
        <q-separator vertical />
        <q-select
          borderless
          class="q-ml-md"
          style="width: 120px"
          v-model="grafMode"
          :options="[
            { label: 'Planerat', value: 'planned' },
            { label: 'Utfall', value: 'actual' },
            { label: 'Alla', value: 'all' },
          ]"
          option-label="label"
          option-value="value"
          map-options
          emit-value
          dense
          square
          stretch
          label="Grafläge"
        />

        <q-separator vertical />
        <q-btn-toggle
          dense
          square
          stretch
          flat
          no-caps
          v-model="timelineZoomMode"
          toggle-color="primary"
          padding="4px 8px"
          :options="[
            { label: '1 min', value: 'minutes' },
            { label: '5 min', value: '5 minutes' },
          ]"
        />
      </q-toolbar> -->
    </q-card-section>
    <q-separator />
    <q-card-section class="q-pa-none" :style="style">
      <canvas ref="grafCanvas"></canvas>
    </q-card-section>
  </q-card>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { useGraph } from '@/composable/useGraph'
import { useRoute } from 'vue-router'
import { useGraphCirculation } from '@/composable/useGraphCirculation'
import { useApp } from '@/composable/useApp'

export default defineComponent({
  setup() {
    const grafCanvas = ref<HTMLCanvasElement>()
    const {
      timelineZoomMode,
      grafMode,
      init,
      model,
      setSelectedTrainNumber,
      setCanvasSize,
      showCirculations,
    } = useGraph()
    const {
      canvasHeight,

      setSelectedTrainNumber: setSelectedTrainNumberCirculation,
    } = useGraphCirculation()
    const rotue = useRoute()
    const { showHeader } = useApp()

    if (
      typeof rotue.params.trainId === 'string' &&
      rotue.params.trainId.length
    ) {
      model.value.trainNumber = rotue.params.trainId
    }

    if (
      typeof rotue.params.departureDate === 'string' &&
      rotue.params.departureDate.length
    ) {
      model.value.departureDate = rotue.params.departureDate
    }

    onMounted(() => {
      if (!grafCanvas.value) return
      init(grafCanvas.value)
    })

    watch(
      () => model.value,
      (value) => {
        setSelectedTrainNumber(value.trainNumber, value.departureDate)
        setSelectedTrainNumberCirculation(
          value.trainNumber,
          value.departureDate
        )
      },
      {
        immediate: true,
        deep: true,
      }
    )

    watch(
      () => [canvasHeight.value, showHeader.value, showCirculations],
      () => {
        setCanvasSize()
      }
    )

    const style = computed(() => {
      return {
        height: `calc(100vh - 178px -  ${
          showCirculations.value ? `${canvasHeight.value}px - 6px` : '4px'
        } + ${showHeader.value ? '0px' : '118px'})`,
      }
    })

    return {
      grafCanvas,
      grafMode,
      timelineZoomMode,
      style,
    }
  },
})
</script>
