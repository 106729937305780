<template>
  <q-page class="bg-grey-1">
    <q-dialog v-model="mpkModal.state.show" :persistent="false">
      <DeviationDelayMPKModal v-if="mpkModal.state.show" />
    </q-dialog>
    <q-dialog v-model="delayModal.state.show" :persistent="false">
      <DeviationDelayModal v-if="delayModal.state.show" />
    </q-dialog>
    <q-dialog v-model="opalModal.state.show" :persistent="false">
      <DeviationDelayMPKMultipleModal v-if="opalModal.state.show" />
    </q-dialog>
    <q-toolbar class="bg-white q-pl-none">
      <q-btn stretch flat no-caps @click="toggleShowHeader">
        <q-icon :name="showHeader ? 'mdi-chevron-up' : 'mdi-chevron-down'" />
        <span class="text-caption q-ml-sm">{{
          showHeader ? 'Dölj navigering' : 'Visa navigering'
        }}</span>
      </q-btn>
      <q-separator vertical />
      <q-btn
        stretch
        flat
        @click="() => (showCirculations = !showCirculations)"
        no-caps
      >
        <q-icon
          :name="showCirculations ? 'mdi-chevron-up' : 'mdi-chevron-down'"
        />
        <span class="text-caption q-ml-sm">{{
          showCirculations ? 'Dölj omlopp' : 'Visa omlopp'
        }}</span>
      </q-btn>
      <q-separator vertical class="q-mr-md" />
      <q-input
        borderless
        dense
        type="number"
        label="Tågnummer"
        v-model="tempTrainNumber"
        @blur="onSearchTrainNumber"
        @change="onSearchTrainNumber"
        @keyup.enter="onSearchTrainNumber"
      >
        <template #prepend>
          <q-icon class="q-mr-md" size="16px" name="mdi-train" />
        </template>
      </q-input>

      <q-separator vertical class="q-mr-md" />

      <q-input
        type="date"
        :model-value="model.departureDate"
        @change="onDepartureDateChange"
        borderless
        dense
        label="Avgångsdatum"
      >
      </q-input>

      <q-space />

      <q-separator vertical class="q-mr-sm" />
      <q-select
        borderless
        class="q-ml-md"
        style="width: 120px"
        v-model="grafMode"
        :options="[
          { label: 'Planerat', value: 'planned' },
          { label: 'Utfall', value: 'actual' },
          { label: 'Alla', value: 'all' },
        ]"
        option-label="label"
        option-value="value"
        map-options
        emit-value
        dense
        square
        stretch
        label="Grafläge"
      />
      <q-separator vertical class="q-ml-md" />
      <q-toggle v-model="showOpal" label="Visa opal varningar" />
      <q-separator vertical class="q-ml-md" />
      <q-btn-toggle
        dense
        square
        stretch
        flat
        no-caps
        v-model="timelineZoomMode"
        toggle-color="primary"
        padding="4px 8px"
        :options="[
          { label: '1 min', value: 'minutes' },
          { label: '5 min', value: '5 minutes' },
        ]"
      />
    </q-toolbar>
    <q-separator />

    <q-linear-progress indeterminate v-if="loading" />

    <div class="row q-col-gutter-xs q-pa-xs">
      <div class="col-12">
        <div class="row q-mb-xs" v-if="showCirculations">
          <div class="col-12">
            <GraphCirculation />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <GraphView />
          </div>
        </div>
      </div>
    </div>
  </q-page>
</template>

<script lang="ts">
import { computed, defineComponent, provide, ref, watch } from 'vue'
import GraphView from '@/components/graph/GraphView.vue'
import GraphCirculation from '@/components/graph/GraphCirculation.vue'
import { useGraph } from '@/composable/useGraph'
import { useApp } from '@/composable/useApp'
import DeviationDelayMPKModal from '@/components/deviation/deviation-delay/DeviationDelayMPKModal.vue'
import DeviationDelayMPKMultipleModal from '@/components/deviation/deviation-delay/DeviationDelayMPKMultipleModal.vue'
import { useModal } from '@/composable/useModal'
import { getMpkEvent } from '@/api/mpk/getMpkEvent'
import { searchDelay } from '@/api/search/searchDelay'
import { searchPrevTripFromTraind } from '@/api/search/prevTripFromTraind'
import DeviationDelayModal from '@/components/deviation/DeviationDelayModal.vue'
import { useGraphCirculation } from '@/composable/useGraphCirculation'
import { MpkEventMessageData } from '@/api/graph/getMpkEventMessage'

export default defineComponent({
  components: {
    GraphView,
    GraphCirculation,
    DeviationDelayMPKModal,
    DeviationDelayModal,
    DeviationDelayMPKMultipleModal,
  },

  setup() {
    const opalModal = useModal()
    const mpkModal = useModal()
    const delayModal = useModal()
    provide('opal-modal', opalModal)
    provide('delay-mpk-modal', mpkModal)
    provide('delay-modal-2', delayModal)
    const {
      model,
      events,
      showCirculations,
      timelineZoomMode,
      grafMode,
      showOpal,
      loading: loadingGraph,
    } = useGraph()
    const { loading: loadingCirculation } = useGraphCirculation()
    const { showHeader } = useApp()

    const tempTrainNumber = ref(model.value.trainNumber)

    watch(
      () => model.value.trainNumber,
      (value) => {
        tempTrainNumber.value = value
      }
    )

    const onSearchTrainNumber = () => {
      model.value.trainNumber = tempTrainNumber.value
    }

    const onDepartureDateChange = (v: string) => {
      if (v.length !== 10) return
      model.value.departureDate = v
    }

    const toggleShowHeader = () => {
      showHeader.value = !showHeader.value
    }

    events.mpk_cb = (mpk_event_id: string) => {
      const eventId = mpk_event_id.split(' ')[0].replace('H', '')
      mpkModal?.openModal({
        cb: async (setData) => {
          await getMpkEvent(eventId)
            .then(({ data }) => {
              setData({
                eventId,
                mpkData: data,
              })
            })
            .catch((error) => {
              setData(error.response.data.message)
            })

          return Promise.resolve()
        },
      })
    }

    events.opal_cb = (mpkMessageData: MpkEventMessageData[]) => {
      opalModal?.openModal({
        cb: async (setData) => {
          setData({
            mpkMessageData,
          })
        },
      })
    }

    events.delay_cb = (advertised: string, departureDate: string) => {
      delayModal?.openModal({
        cb: async (setData) => {
          const params = {
            technical: advertised,
            departureDate: departureDate,
          }
          await Promise.all([
            searchDelay(params),
            searchPrevTripFromTraind({
              tnr: parseInt(params.technical, 10),
              date: params.departureDate,
            }),
          ])
            .then(([{ data }, { data: prevTripFromTraind }]) => {
              setData({
                data,
                prevTripFromTraind,
              })
            })
            .catch((error) => {
              setData(error.response.data.message)
            })

          return Promise.resolve()
        },
      })
    }

    const loading = computed(() => {
      return loadingGraph.value || loadingCirculation.value
    })

    return {
      onSearchTrainNumber,
      tempTrainNumber,
      model,
      onDepartureDateChange,
      toggleShowHeader,
      showHeader,
      mpkModal,
      delayModal,
      showCirculations,
      timelineZoomMode,
      grafMode,
      loading,
      opalModal,
      showOpal,
    }
  },
})
</script>
