<template>
  <q-card style="max-width: 900px; max-height: 800px">
    <q-card-section class="row items-center">
      <span class="text-h6">TRV händelser</span>
      <q-space />
      <q-toggle v-model="showRawdata" label="Visa rådata" />
    </q-card-section>
    <q-separator />
    <q-card-section class="q-pa-none">
      <q-tabs
        v-model="activeTab"
        dense
        class="text-grey"
        active-color="primary"
        indicator-color="primary"
        align="justify"
      >
        <q-tab
          :name="$i"
          :label="item.EventId"
          v-for="(item, $i) in mpkMessageData"
          :key="$i"
        />
      </q-tabs>
    </q-card-section>
    <q-separator />
    <q-card-section class="q-pa-none">
      <q-tab-panels v-model="activeTab" animated>
        <q-tab-panel :name="$i" v-for="(item, $i) in mpkMessageData" :key="$i">
          <table v-if="item" style="table-layout: fixed; width: 100%">
            <tbody>
              <template v-for="h in headers" :key="h.key">
                <tr v-if="item[h.key]">
                  <td
                    style="
                      background: #f2f2f2;
                      font-weight: bold;
                      width: 200px;
                      vertical-align: top;
                      border-right: 1px solid rgba(0, 0, 0, 0.1);
                      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    "
                    class="q-px-md q-py-xs"
                  >
                    {{ h.label }}
                  </td>
                  <td style="border-bottom: 1px solid rgba(0, 0, 0, 0.1)">
                    <div
                      style="overflow: auto; max-height: 200px"
                      class="q-px-md"
                    >
                      {{ item[h.key] }}
                    </div>
                  </td>
                </tr>
              </template>
              <tr v-if="showRawdata">
                <td
                  style="
                    width: 200px;
                    vertical-align: top;
                    background: #f2f2f2;
                    font-weight: bold;
                    border-right: 1px solid rgba(0, 0, 0, 0.1);
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                  "
                  class="q-px-md q-py-xs"
                >
                  Rådata
                </td>
                <td style="border-bottom: 1px solid rgba(0, 0, 0, 0.1)">
                  <pre
                    style="
                      overflow: scroll;
                      max-height: 400px;
                      margin: 0;
                      font-size: 11px;
                      color: #444;
                    "
                    class="q-pa-md"
                  >
                  
                  {{ JSON.stringify(item, null, 2).trim() }}
                
                </pre
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </q-tab-panel>
      </q-tab-panels>
    </q-card-section>

    <q-separator />
    <q-card-actions align="right">
      <q-btn flat label="Stäng" @click="modal.closeModal" type="button" />
    </q-card-actions>
  </q-card>
</template>

<script lang="ts">
import { UseModal } from '@/composable/useModal'
import { defineComponent, inject, ref } from 'vue'
import { MpkEventMessageData } from '@/api/graph/getMpkEventMessage'

export default defineComponent({
  name: 'DeviationDelayMPKModal',

  setup() {
    const showRawdata = ref(false)
    const modal =
      inject<UseModal<{ mpkMessageData: MpkEventMessageData[] }>>('opal-modal')!
    const { mpkMessageData } = modal.state.data!
    console.log(mpkMessageData)

    const activeTab = ref(0)

    const headers = [
      {
        label: 'Rubrik',
        key: 'Header',
      },
      {
        label: 'Händelsebeskrivning (intern)',
        key: 'InternalText',
      },
      {
        label: 'Händelsebeskrivning (extern)',
        key: 'ExternalDescription',
      },
    ]

    return { modal, mpkMessageData, headers, activeTab, showRawdata }
  },
})
</script>
