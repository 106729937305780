// import { instance } from '@/services/avik-api-gateway'
// import { AxiosResponse } from 'axios'
import data from './track_count_data.json'

interface TrackCount {
  from: string
  to: string
  track_count: number
}

export function getTrackCount(): Promise<TrackCount[]> {
  return Promise.resolve(data)
}
