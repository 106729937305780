import { instance } from '@/services/avik-api-gateway'
import { AxiosResponse } from 'axios'
import { VehicleDefectByVehicle } from '../vehicle-defect/getVehicleDefectByVehicle'

export function getFordDefects(
  vehicleName: string,
  timestamp: string
): Promise<AxiosResponse<VehicleDefectByVehicle[]>> {
  return instance.get(
    `/vehicle-defect/${vehicleName}?top=5&timestamp=${timestamp}`
  )
}
