import { instance } from '@/services/avik-api-gateway'
export interface MpkEventMessageData {
  AssumedTrafficImpact: boolean
  Deleted: boolean
  EndDateTime: string
  EventId: string
  ExternalVersion: number
  Header: string
  IncidentVersion: number
  InternalText: string
  InternalVersion: number
  LastUpdateDateTime: string
  ModifiedTime: string
  Removed: false
  PrognosticatedEndDateTimeTrafficImpact: string
  PredictedEndDateTime: string
  ReasonCode: [
    {
      Code: string
      Description: string
    }
  ]
  RegistrationDateTime: string
  ReOpened: boolean
  StartDateTime: string
  StopDateTime: string
  ScheduledDeparture: string
  SpeedRestriction: false
  TrafficLocations: [
    {
      FromLocation: {
        Signature: string
        XCoordinate: number
        YCoordinate: number
        FromLocationIncluded: boolean
      }
      ToLocation: {
        Signature: string
        XCoordinate: number
        YCoordinate: number
        ToLocationIncluded: boolean
      }
    }
  ]
  PåverkadeSträckor: [[string, string]]
}

export function getTrackCancellation(params: {
  fromTime: string
  toTime: string
  places: string[]
}): Promise<MpkEventMessageData[]> {
  return instance
    .get<MpkEventMessageData[]>(`/mpk/sparavstangning/time-place-filter`, {
      params,
    })
    .then((res) => {
      return res.data
    })
}
