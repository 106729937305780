
import { defineComponent, onMounted, ref } from 'vue'
import { useGraphCirculation } from '@/composable/useGraphCirculation'

export default defineComponent({
  setup() {
    const canvas = ref<HTMLCanvasElement>()
    const { init, circulations, loading } = useGraphCirculation()

    onMounted(() => {
      if (!canvas.value) return

      init(canvas.value)
    })

    return {
      canvas,
      circulations,
      loading,
    }
  },
})
