import { instance } from '@/services/avik-api-gateway'
import { AxiosResponse } from 'axios'
import { GraphCirculationTrip } from '@/composable/useGraphCirculation'

export function getTrainCirculation(params: {
  departureDate: string
  trainId: number | string
}): Promise<AxiosResponse<GraphCirculationTrip[]>> {
  return instance.get(`/graph/traind-circulation`, {
    params,
  })
}
