import { render } from "./GraphPage.vue?vue&type=template&id=8724b626"
import script from "./GraphPage.vue?vue&type=script&lang=ts"
export * from "./GraphPage.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QPage from 'quasar/src/components/page/QPage.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';
import QLinearProgress from 'quasar/src/components/linear-progress/QLinearProgress.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QDialog,QToolbar,QBtn,QIcon,QSeparator,QInput,QSpace,QSelect,QToggle,QBtnToggle,QLinearProgress});
